<template>
  <div class="whySelect">
    <div class="box">
      <div class="content">
        <div class="item">
          <div class="icon">
            <img :src="$imgs[`home/whySelect_icon1.png`]" alt="" />
          </div>
          <div class="tit" v-html="$t('Index.无限数据放心用(1)')"></div>
          <div class="cont">{{ $t('Index.生活可能有好多限制') }}</div>
        </div>
        <div class="item">
          <div class="icon">
            <img :src="$imgs[`home/whySelect_icon2.png`]" alt="" />
          </div>
          <div class="tit" v-html="$t('Index.旅行漫游想走就走')"></div>
          <div class="cont">{{ $t('Index.灵活加购漫游数据') }}</div>
        </div>
        <div class="item">
          <div class="icon">
            <img :src="$imgs[`home/whySelect_icon3.png`]" alt="" />
          </div>
          <div class="tit" v-html="$t('Index.年青人出機必備')"></div>
          <div class="cont">{{ $t('Index.享5G手机奖赏热门品牌5G手机出机无忧') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { navigate } from 'vite-plugin-ssr/client/router'
const { locale } = useI18n()
function goRouter(name: string) {
  navigate('/' + locale.value + name)
}
</script>
<style scoped lang="less">
@width: 1920;
.blue {
  color: #00a7ff;
}
.whySelect {
  background: url('@/assets/imgs/home/whySelect_bg.png') no-repeat center center;
  background-size: 100%;
  padding-bottom: 37px;
  .box {
    padding-top: 2vw;
  }
  .content {
    width: (1324 / @width * 100%);
    display: flex;
    margin: 0 auto;
    border-radius: 1.25vw;
    border: 2px solid #f3faff;
    .item {
      padding: 1.5625vw 0 2.8125vw;
      text-align: center;
      flex: 1;
      .icon {
        width: 22.29vw;
        height: auto;
        img {
          width: 100%;
        }
      }
      .tit {
        padding: 1.041vw 1.0412vw;
        font-size: 1.25vw;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        line-height: 2.083vw;
      }
      .cont {
        padding: 0 1.0412vw;
        font-size: 1.041vw;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 1.666vw;
        white-space: pre-wrap;
      }
      &:nth-of-type(2) {
        margin: 0 1.041vw;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  @width: 375;
  .whySelect {
    background: #ebfbff url('@/assets/imgs/home/whySelect_bg1.png') no-repeat center top;
    background-size: 100%;
    // background: url('@/assets/imgs/home/whySelect_bg1.png') no-repeat center top;
    // background-size: 100% 100%;
    margin-bottom: 0;
    .box {
      padding-top: 16px;
    }
    .content {
      width: 100%;
      display: block;
      margin: 0 auto;
      border-radius: 0;
      border: none;
      padding-bottom: 30px;
      .item {
        border-radius: 24px;
        background: white;
        width: (343 / @width * 100%);
        padding: 0 0 32px 0;
        margin: 0 auto 16px;
        text-align: center;
        border: 2px solid #f3faff;
        .icon {
          width: 100%;
          margin: 16px auto 0;
          height: auto;
          img {
            width: 100%;
          }
        }
        .tit {
          padding: 12px 16px;
          font-size: 20px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.8);
          line-height: 30px;
        }
        .cont {
          padding: 0 16px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          line-height: 24px;
        }
        &:nth-of-type(2) {
          margin: 0 auto 16px;
        }
      }
    }
  }
}
</style>
